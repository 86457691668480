import { EMAIL_TYPE, FULL_UNIFORM_STATEMENT, TEAM_LEVEL } from './constants';
import { getTimeOffsets, parseDateTime } from './utils';

const getUniformStatement = (addUniformStatement: boolean | undefined) =>
  addUniformStatement ? `\n${FULL_UNIFORM_STATEMENT}\n` : '';

type EmailContentData = {
  athleticDirector: string;
  selectedDate: any;
  level: TEAM_LEVEL;
  school: string;
  addUniformStatement?: boolean;
};

export type EmailGenerator = (content: EmailContentData) => string;

export const getJrVarsityADContent: EmailGenerator = ({ athleticDirector, selectedDate }) => {
  const { day, date, time } = parseDateTime(selectedDate);
  return `${athleticDirector},

I am scheduled as the referee for your JV football game ${day}, ${date}. I wanted to confirm a few details prior to the crew's arrival.

Our assignment shows the game time as ${time}. Is this correct?

Who will be the game day administrator for the evening?

Is there a specific location you would like for the crew to park?

Will you have a play clock and game clock operator or will we be keeping the play clock on the field? We will need to meet with the game clock and play clock operators 30 minutes prior to kickoff on the field to review clock procedures with them.

Please let me know if there is anything we need to do or be aware of prior to working the game and I will pass along to the crew.

Should weather be an issue, feel free to email me at this address or call my cell and I will coordinate that information with the crew. My cell phone number is (910) 331-4481.

The crew and I are looking forward to working the game this ${day}.

Thanks,`;
};

export const getJrVarsityCrewContent: EmailGenerator = ({
  school,
  selectedDate,
  level,
  addUniformStatement,
}) => {
  const { day, date, time } = parseDateTime(selectedDate);
  const { fieldTime, arrivalTime } = getTimeOffsets(selectedDate, level);

  return `We have a JV football game at ${school} ${day}, ${date}. Game time is scheduled for ${time}. Let's plan on arriving at ${arrivalTime} so we can be ready to take the field at ${fieldTime} to meet with the coaches.
${getUniformStatement(addUniformStatement)}
Please confirm you received the email. Looking forward to working with everyone.

Thanks,`;
};

export const getVarsityADContent: EmailGenerator = ({ athleticDirector, selectedDate }) => {
  const { day, date, time } = parseDateTime(selectedDate);

  return `${athleticDirector},

I am scheduled as the referee for your varsity football game ${day}, ${date}. I would like to confirm a few details prior to the crew's arrival.

Our assignment shows the game time as ${time}. Is this correct?

Who will be the game day administrator for the evening?

Is there any specific location the officials should park? 

Will you have a play clock and game clock operator or will we be keeping the play clock on the field? We will need to meet with the game clock and play clock operators on the field prior to the game to review clock procedures with them.

Please let me know if there is anything we need to do or be aware of prior to working the game and I will pass along to the crew.

Should weather be an issue, feel free to email me at this address or call my cell and I will coordinate that information with the crew. My cell phone number is (910) 331-4481.

The crew and I are looking forward to working the game this ${day}.

Thanks,`;
};

export const getVarsityCrewContent: EmailGenerator = ({
  school,
  selectedDate,
  level,
  addUniformStatement,
}) => {
  const { day, date, time } = parseDateTime(selectedDate);
  const { fieldTime, pregameTime, arrivalTime } = getTimeOffsets(selectedDate, level);

  return `We have a varsity football game at ${school} ${day}, ${date}. Game time is scheduled for ${time}. Let’s plan on arriving at ${arrivalTime} so we can begin pregame at ${pregameTime} and be on the field to meet with the coaches at ${fieldTime}.
${getUniformStatement(addUniformStatement)}
I have emailed the athletic director to confirm a location for our pregame. I will pass along any information I get. 

Please reply and confirm you got the message. Looking forward to working with the crew.

Have a good week.

Thanks,`;
};

export const emailGenerators = {
  [TEAM_LEVEL.jv]: {
    [EMAIL_TYPE.athleticDirector]: getJrVarsityADContent,
    [EMAIL_TYPE.crew]: getJrVarsityCrewContent,
  },
  [TEAM_LEVEL.varsity]: {
    [EMAIL_TYPE.athleticDirector]: getVarsityADContent,
    [EMAIL_TYPE.crew]: getVarsityCrewContent,
  },
};
